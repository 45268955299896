<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="操作描述">
                <el-input v-model="search.description" size="small"/>
            </x-search-item>
            <x-search-item label="操作时间" hasEnd text-width="230px">
                <el-date-picker v-model="search.operateTimeStart" size="small" type="datetime"/>
                <el-date-picker v-model="search.operateTimeEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="高级查询" hasEnd>
                <el-select v-model="highSearch" placeholder="请选择" size="small">
                    <el-option label="用户名称" value="userName"></el-option>
                    <el-option label="操作类型" value="operateType"></el-option>
                    <el-option label="功能密级" value="operateClassification"></el-option>
                    <el-option label="是否准入" value="succeed"></el-option>
                </el-select>
                <template slot="end">
                    <el-input v-show="highSearch === 'userName'" v-model="search.userName" size="small"/>
                    <x-selector-one v-show="highSearch === 'operateType'" v-model="search.operateType" size="small" dictType="T_OPERATE"/>
                    <x-selector-one v-show="highSearch === 'operateClassification'" v-model="search.operateClassification" size="small"
                                    dictType="T_SECURITY_CLASSIFICATION"/>
                    <x-selector-one v-show="highSearch === 'succeed'" v-model="search.succeed" size="small" dictType="T_YES_OR_NO"/>
                </template>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column show-overflow-tooltip prop="description" label="操作描述"/>
            <el-table-column width="100" label="操作类型">
                <x-dict-show slot-scope="{row}" :code="row.operateType" dictType="T_OPERATE"/>
            </el-table-column>
            <el-table-column width="100" label="功能密级">
                <x-dict-show slot-scope="{row}" :code="row.operateClassification" dictType="T_SECURITY_CLASSIFICATION"/>
            </el-table-column>
            <el-table-column width="100" label="用户密级">
                <x-dict-show slot-scope="{row}" :code="row.userClassification" dictType="T_SECURITY_CLASSIFICATION"/>
            </el-table-column>
            <el-table-column width="120" prop="userName" label="用户名称"/>
            <el-table-column width="200" prop="operateTime" label="操作时间"/>
            <el-table-column show-overflow-tooltip width="200" prop="uri" label="uri"/>
            <el-table-column width="150" prop="ip" label="客户端ip"/>
            <el-table-column width="100" label="是否准入">
                <x-dict-show slot-scope="{row}" :code="row.succeed" dictType="T_YES_OR_NO"/>
            </el-table-column>
            <el-table-column width="150" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {list} from "@/service/system/accessLog";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/system/accessLog/AccessLogDetail";

    export default {
        name: "AccessLogList",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = list;
            return {
                highSearch: 'userName',
                search: {
                    description: '',
                    operateClassification: '',
                    operateType: '',
                    succeed: '',
                    userName: '',
                    operateTimeStart: '',
                    operateTimeEnd: ''
                }
            }
        }
    }
</script>

<style scoped>
</style>
