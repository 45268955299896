import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/access-log/list',
        method: 'post',
        data
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/access-log/get-detail/${id}`,
        method: 'get',
    })
}
