<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open" center append-to-body width="1000px">
        <x-detail :entity="entity">
            <x-detail-item prop="description" label="操作描述"/>
            <x-detail-item label="操作类型">
                <x-dict-show :code="entity.operateType" dictType="T_OPERATE"/>
            </x-detail-item>
            <x-detail-item label="功能密级">
                <x-dict-show :code="entity.operateClassification" dictType="T_SECURITY_CLASSIFICATION"/>
            </x-detail-item>
            <x-detail-item label="用户密级">
                <x-dict-show :code="entity.userClassification" dictType="T_SECURITY_CLASSIFICATION"/>
            </x-detail-item>
            <x-detail-item prop="userName" label="操作用户"/>
            <x-detail-item prop="operateTime" label="操作时间"/>
            <x-detail-item prop="method" label="请求类型"/>
            <x-detail-item prop="ip" label="客户端IP地址"/>
            <x-detail-item prop="spendTime" label="消耗时间(ms)"/>
            <x-detail-item prop="succeed" label="是否准入">
                <x-dict-show :code="entity.succeed" dictType="T_YES_OR_NO"/>
            </x-detail-item>
            <x-detail-item prop="basePath" label="根路径" class="line"/>
            <x-detail-item prop="uri" label="uri" class="line"/>
            <x-detail-item prop="url" label="url" class="line"/>
            <x-detail-item prop="parameter" label="参数" class="line"/>
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/system/accessLog";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                description: null,
                operateType: null,
                operateClassification: null,
                userClassification: null,
                userName: null,
                method: null,
                ip: null,
                spendTime: null,
                succeed: null,
                basePath: null,
                uri: null,
                url: null,
                parameter: null
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>